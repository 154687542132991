.wrapper {
  max-width: 820px;
  margin: 0 auto;
  background: #fff;
}

.authGateWrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  max-width: 540px;
  margin: 0 auto;
  min-height: 100vh;
}

.nav {
  padding-top: 60px;
}

.authGateContent {
  border-bottom: 1px solid #e4e4e7;
  padding-bottom: 28px;
}

.authGateContent > svg {
  display: block;
  margin: 0 auto;
}

.btnContainer {
  min-height: 204px;
}

.heroTitle {
  font-weight: 700;
  font-size: 40px;
  line-height: 110%;
  letter-spacing: -0.03em;
  color: #3f3f46;
  margin: 60px 0 30px;
}

.loginSection {
  background: #f4f4f5;
  min-height: 100vh;
}
